import React, {useCallback, useState} from 'react';

import {TourDrawer} from '../../components/TourDrawer';
import Typography from '@mui/material/Typography';
import {LinearProgress, LinearProgressProps, Stack} from '@mui/material';
import {TruvuButton} from '../../../../components/button/TruvuButton';
import {Add} from '@mui/icons-material';
import {useTheme} from '@mui/material/styles';
import graphql from 'babel-plugin-relay/macro';
import {EditMarkerWithType, TourEditMarker} from './TourEditMarker';
import gsap from 'gsap';
import {useRefetchable} from 'relay-hooks';
import {TourEditDrawerMarkers_tour$key} from '../../../../__generated__/TourEditDrawerMarkers_tour.graphql';
import {useMutation} from 'react-relay-mutation';
import {TourEditDrawerGenerateAIMarkersMutation} from '../../../../__generated__/TourEditDrawerGenerateAIMarkersMutation.graphql';
import Box from '@mui/material/Box';
import {TruvuMessageDialog} from '../../../../components/dialog/TruvuMessageDialog';
import {useTruvuDialog} from '../../../../components/dialog';
import {useTourAIGenerateMarkersSubscriptionContext} from '../../../../context/TourAIGenerateMarkersSubscriptionContext';
import {FrameStoreBuffer} from '../../types';
import {TruvuIconButton} from '../../../../components/button/TruvuIconButton';
import {useAuthContext} from '../../../../context/AuthContext';

const fragment = graphql`
  fragment TourEditDrawerMarkers_tour on Tour
  @refetchable(queryName: "TourEditDrawerMarkersTodoRefetchQuery") {
    markers {
      id
      name
      isFastTravel
      frameIndex
    }
  }
`;

interface TourEditDrawerProps {
  keepDrawerOpen: boolean;
  currentIndex: number;
  setCurrentIndex: React.Dispatch<React.SetStateAction<number>>;
  drawerIsOpen: boolean;
  setFastTravellingStatus: React.Dispatch<
    React.SetStateAction<'idle' | 'forward' | 'backward'>
  >;
  setDrawerIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  buffer: FrameStoreBuffer;
  tourRef: TourEditDrawerMarkers_tour$key;
  drawerRef: React.RefObject<HTMLDivElement>;
  onOpenAddMarkersDialog: () => void;
}
/** The lower the value, the quicker it navigates */
const DURATION_RATIO = 0.002;

const isErrorWithSource = (
  error: unknown
): error is {source: {errors: Array<{message: string}>}} => {
  return typeof error === 'object' && error != null && 'source' in error;
};

export function TourEditDrawer({
  keepDrawerOpen,
  drawerIsOpen,
  setDrawerIsOpen,
  setFastTravellingStatus,
  tourRef,
  drawerRef,
  onOpenAddMarkersDialog,
  currentIndex,
  setCurrentIndex,
  buffer,
}: TourEditDrawerProps) {
  const theme = useTheme();
  const {isSuperAdmin} = useAuthContext();
  const {data: tour, refetch, isLoading} = useRefetchable(fragment, tourRef);
  const [navigatingIndex, setNavigatingIndex] = React.useState<number | null>(
    null
  );
  const timeoutRef = React.useRef<NodeJS.Timeout>();
  const {
    openDialog: openGenerateErrorDialog,
    onOpenDialog: onOpenGenerateErrorDialog,
    onCloseDialog: onCloseGenerateErrorDialog,
  } = useTruvuDialog(false);
  const [generateErrorMessage, setGenerateErrorMessage] = useState<
    string | null
  >(null);
  const {
    generationProgress,
    activeTourId,
    generatedMarkers,
    onSubscribeToAIGeneration,
  } = useTourAIGenerateMarkersSubscriptionContext();
  console.log(isSuperAdmin());
  const [
    tourGenerateAIMarkerMutation,
    {loading: generatingAIMarkers},
  ] = useMutation<TourEditDrawerGenerateAIMarkersMutation>(
    graphql`
      mutation TourEditDrawerGenerateAIMarkersMutation($tourId: String!) {
        tourGenerateAIMarkers(input: {tourId: $tourId}) {
          tour {
            id
            markers {
              id
              name
              description
              scrollPosition
              frameIndex
            }
          }
        }
      }
    `,
    {
      onError: (error) => {
        onOpenGenerateErrorDialog();
        if (isErrorWithSource(error)) {
          setGenerateErrorMessage(
            error.source?.errors?.[0]?.message ?? error.message
          );
        } else {
          setGenerateErrorMessage(error.message);
        }
      },
      onCompleted: () => {
        setTimeout(() => {
          refetch({});
        }, 1000);
      },
    }
  );

  const tourGenerateAIMarker = useCallback(
    (tourId: string) => {
      const response = onSubscribeToAIGeneration(tourId, () => {
        refetch({});
      });
      if (response === 'subscribed') {
        tourGenerateAIMarkerMutation({
          variables: {tourId: tourId},
        });
      }
    },
    [onSubscribeToAIGeneration, refetch, tourGenerateAIMarkerMutation]
  );

  const handleRefetch = useCallback(() => {
    refetch({});
  }, [refetch]);

  const sortedMarkers = React.useMemo(() => {
    const markersMap = new Map<string, EditMarkerWithType>();

    tour?.markers.forEach((marker) => {
      markersMap.set(marker.id, {...marker, type: 'existed'});
    });

    if (activeTourId === tour?.id) {
      generatedMarkers.forEach(({action, ...generatedMarker}) => {
        if (generatedMarker.id != null) {
          markersMap.set(generatedMarker.id, {
            ...generatedMarker,
            type: action === 'REMOVE' ? 'removed' : 'added',
          });
        }
      });
    }

    return Array.from(markersMap.values()).sort(
      (a, b) => a.frameIndex - b.frameIndex
    );
  }, [activeTourId, generatedMarkers, tour?.id, tour?.markers]);

  const onMarkerClick = React.useCallback(
    (frameIndex) => {
      if (timeoutRef.current != null) {
        clearTimeout(timeoutRef.current);
      }
      if (frameIndex > currentIndex) {
        setFastTravellingStatus('forward');
      } else {
        setFastTravellingStatus('backward');
      }
      timeoutRef.current = setTimeout(() => {
        setFastTravellingStatus('idle');
      }, 2500);
      setNavigatingIndex(frameIndex);
      const distance = currentIndex - frameIndex;
      gsap.to(frameIndex, {
        ease: 'power3.out', // easing function
        duration: Math.abs(distance) * DURATION_RATIO,
        onComplete: () => {
          setNavigatingIndex(null);
        },
        onUpdate: () => {
          const roundedVelocity = 0.033 / DURATION_RATIO;
          setCurrentIndex((prev) => {
            if (prev === frameIndex) {
              return prev;
            }
            const newVal =
              distance > 0 ? prev - roundedVelocity : prev + roundedVelocity;
            return distance > 0
              ? Math.max(newVal, frameIndex)
              : Math.min(newVal, frameIndex);
          });
        },
      });
    },
    [currentIndex, setCurrentIndex, setFastTravellingStatus]
  );

  return (
    <>
      <TruvuMessageDialog
        title={'AI Markers already created on this tour.'}
        variant="error"
        message={
          generateErrorMessage ? (
            <Typography>
              Failed to generate AI markers: {generateErrorMessage}
            </Typography>
          ) : null
        }
        actions={
          <TruvuButton
            variant="secondary"
            onClick={() => {
              onCloseGenerateErrorDialog();
            }}
          >
            Close
          </TruvuButton>
        }
        isOpen={openGenerateErrorDialog}
        onOpen={onOpenGenerateErrorDialog}
        onClose={onCloseGenerateErrorDialog}
      />
      <TourDrawer
        keepDrawerOpen={keepDrawerOpen}
        open={drawerIsOpen}
        setOpen={setDrawerIsOpen}
        drawerRef={drawerRef}
      >
        <Stack
          direction="row"
          justifyContent="space-between"
          spacing={1}
          mb={2}
          position="sticky"
          top={0}
        >
          <Typography variant="h2">Fast Travel</Typography>
          <TruvuIconButton
            label="Add"
            onClick={onOpenAddMarkersDialog}
            size="small"
          >
            <Add fontSize="inherit" />
          </TruvuIconButton>
        </Stack>

        {/* Only show AI features for superadmin */}
        {isSuperAdmin() && (
          <>
            {generatingAIMarkers ||
            (activeTourId === tour.id && generationProgress > 0) ? (
              <>
                <Typography color={'primary'}>Generating with AI</Typography>
                <LinearProgressWithLabel
                  variant={generationProgress === 0 ? 'query' : 'determinate'}
                  value={generationProgress}
                />
              </>
            ) : (
              <Typography
                variant="body1"
                color="text.secondary"
                sx={{cursor: 'pointer'}}
                onClick={() => tourGenerateAIMarker(tour.id)}
                position="sticky"
                top={0}
                mb={2}
              >
                <span style={{color: theme.palette.primary.main}}>
                  Auto generate
                </span>{' '}
                with AI (beta)
              </Typography>
            )}
          </>
        )}

        {sortedMarkers?.length === 0 && (
          <Typography color="text.secondary" mt={1} mb={2}>
            Add location markers for your viewers to teleport through your
            property.
          </Typography>
        )}

        {isLoading && <LinearProgress variant="query" sx={{mb: 2}} />}

        <Stack spacing={0.8}>
          {sortedMarkers?.map((marker) => (
            <TourEditMarker
              key={marker.id}
              marker={marker}
              refetch={handleRefetch}
              isLoading={isLoading}
              disabled={
                marker.frameIndex >= buffer.storeLength ||
                navigatingIndex != null
              }
              isActive={Math.abs(currentIndex - marker.frameIndex) <= 0.5}
              onClick={onMarkerClick}
            />
          ))}
        </Stack>
      </TourDrawer>
    </>
  );
}

function LinearProgressWithLabel(props: LinearProgressProps & {value: number}) {
  return (
    <Box sx={{display: 'flex', alignItems: 'center', mb: 2}}>
      <LinearProgress variant="determinate" sx={{flex: 1, mr: 1}} {...props} />
      <Typography variant="body2" color="text.secondary">{`${Math.round(
        props.value
      )}%`}</Typography>
    </Box>
  );
}
